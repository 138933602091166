// src/router.js
import { createRouter, createWebHistory } from "vue-router";
import WebSocketData from "./components/WebSocketData.vue";

const routes = [
  {
    path: "/",
    name: "WebSocketData",
    component: WebSocketData,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
