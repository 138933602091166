<template>
    <div>
        <div class="container mt-5">
            <div class="w-100 d-flex align-items-center justify-content-end" style="margin-bottom: 25px;">
                <!-- <div class="d-flex align-items-center">
                    <span style="margin-right: 10px">Base Difference Notification :</span>
                    <label class="switch">
                        <input type="checkbox" v-model="enableBaseNotification">
                        <span class="slider"></span>
                    </label>
                </div> -->
                <div class="d-flex align-items-center">
                    <span style="margin-right: 10px">Base Value Increase Notification : </span>
                    <label class="switch">
                        <input type="checkbox" v-model="enableCallBaseNotification">
                        <span class="slider"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center" style="margin-left: 15px;">
                    <span style="margin-right: 10px">Base Value Decrease Notification : </span>
                    <label class="switch">
                        <input type="checkbox" v-model="enablePutBaseNotification">
                        <span class="slider"></span>
                    </label>
                </div>
                <div v-if="enableCallBaseNotification || enablePutBaseNotification" class="d-flex align-items-center"
                    style="margin-left: 15px;">
                    <label for="baseDifference" style="margin-right: 5px">Set Base Difference :</label>
                    <select v-model="selectedBaseDifference" id="number-base-difference"
                        @change="console.log(selectedBaseDifference)">
                        <option v-for="number in baseDifferenceLimits" :key="number" :value="number">
                            {{ number }}
                        </option>
                    </select>
                </div>
                <div class="d-flex align-items-center" style="margin-left: 15px;">
                    <span style="margin-right: 10px;">Show Data Table :</span>
                    <label class="switch">
                        <input type="checkbox" v-model="toggleDataTable">
                        <span class="slider"></span>
                    </label>
                </div>
            </div>

            <!-- Data Tables for Call and Put sides -->
            <div v-if="!toggleDataTable" id="dataTable" style="margin-top: 45px;">
                <div>
                    <div style="margin-bottom: 25px;">
                        <div class="d-flex align-items-center justify-content-end mb-4">

                            <div class="d-flex align-items-center" style="margin-left: 15px;">
                                <span style="margin-right: 5px">Call Difference Notification :</span>
                                <label class="switch">
                                    <input type="checkbox" v-model="enableCallNotification">
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <div v-if="enableCallNotification" class="d-flex align-items-center"
                                style="margin-left: 15px;">
                                <label for="callSound" style="margin-right: 5px">Select Sound :</label>
                                <select id="callSound" v-model="selectedCallSound" @change="saveCallSound">
                                    <option v-for="sound in availableSounds" :key="sound" :value="sound">{{
                                        sound.substring(0,
                                            sound.lastIndexOf('.')) }}</option>
                                </select>
                            </div>
                        </div>

                        <DataTable side="CALL" :latestData="latestData" :selectedLimit="selectedCallLimit"
                            :limits="callLimits" @update:selectedLimit="saveCallLimit" />


                        <div v-if="this.cookieCallOrderData && this.selectedOrderCallType !== 'MKT' && this.selectedOrderCallType !== 'TRAIL'"
                            class="w-100 mb-4">
                            <table class="table table-bordered table-striped table-hover table-responsive">
                                <thead>
                                    <tr>
                                        <td colspan="8" style="text-align: center; font-weight: bold;">
                                            <h5>Call Order Details</h5>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><strong>Symbol</strong></td>
                                        <td><strong>Quantity</strong></td>
                                        <td><strong>Order Type</strong></td>
                                        <td><strong>Limit Price</strong></td>
                                        <td><strong>Aux Price</strong></td>
                                        <td><strong>Strike</strong></td>
                                        <td><strong>Security Type</strong></td>
                                        <td><strong>Exchange</strong></td>
                                    </tr>
                                    <tr>
                                        <td>{{ this.cookieCallOrderData.symbol }}</td>
                                        <td>{{ this.cookieCallOrderData.quantity }}</td>
                                        <td>{{ this.cookieCallOrderData.orderType }}</td>
                                        <td>{{ this.cookieCallOrderData.lmtPrice }}</td>
                                        <td>{{ this.cookieCallOrderData.auxPrice }}</td>
                                        <td>{{ this.cookieCallOrderData.strike }}</td>
                                        <td>OPT</td>
                                        <td>SMART</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="d-none flex-column align-items-center justify-content-center">
                            <div class="d-flex align-items-center justify-content-center mb-4">
                                <div class="mx-2">
                                    <label for="number-select" style="margin-right: 5px">Select a size :</label>
                                    <select v-model="selectedCallSize" id="number-select">
                                        <option v-for="number in orderSizeLimits" :key="number" :value="number">
                                            {{ number }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mx-2">
                                    <label for="order-type" style="margin-right: 5px">Select a order type :</label>
                                    <select v-model="selectedOrderCallType" id="order-type"
                                        @change="(e) => handleOrderType(e.currentTarget.value, 'CALL')">
                                        <option v-for="orderType in orderTypeLimits" :key="orderType"
                                            :value="orderType">
                                            {{ orderType }}
                                        </option>
                                    </select>
                                </div>
                                <div v-if="selectedOrderCallType == 'TRAIL'" class="mx-2">
                                    <label for="trail-type-call" style="margin-right: 5px">Select a Trail Percentage
                                        :</label>
                                    <select v-model="selectedTrailCallPercentage" id="trail-type-call">
                                        <option v-for="percentage in trailCallPercentage" :key="percentage"
                                            :value="percentage">
                                            {{ percentage }}
                                        </option>
                                    </select>
                                </div>
                                <div v-if="selectedOrderCallType == 'STP' || selectedOrderCallType == 'STP_LMT'"
                                    class="mx-2">
                                    <div class="pt-2 d-flex align-items-center justify-content-center">
                                        <p class="mb-0 mx-2">{{ stopCallPrice[0] }}</p>
                                        <b-form-input id="stopPriceSelectCall" v-model="selectedNumberStopCall"
                                            type="range" :min="stopCallPrice[0]" :max="stopCallPrice[1]"
                                            @click="handleOrderData('CALL')" step="0.01"></b-form-input>
                                        <p class="mb-0 mx-2">{{ stopCallPrice[1] }}</p>
                                    </div>
                                    <p class="mt-1 text-center">Stop Price : {{ selectedNumberStopCall }}</p>
                                </div>
                                <div v-if="selectedOrderCallType == 'LMT' || selectedOrderCallType == 'STP_LMT'"
                                    class="mx-2">
                                    <div class="pt-2 d-flex align-items-center justify-content-center">
                                        <p class="mb-0 mx-2">{{ limitCallPrice[0] }}</p>
                                        <b-form-input id="limitPriceSelect" v-model="selectedNumberLimitCall"
                                            type="range" :min="limitCallPrice[0]" :max="limitCallPrice[1]"
                                            @click="handleOrderData('CALL')" step="0.01"></b-form-input>
                                        <p class="mb-0 mx-2">{{ limitCallPrice[1] }}</p>
                                    </div>
                                    <p class="mt-1 text-center">Limit Price : {{ selectedNumberLimitCall }}</p>
                                </div>
                                <b-button class="mx-2" @click="buyOrder('CALL')">Buy</b-button>
                                <b-button v-if="this.cookieCallOrderData" class="m-2"
                                    @click="this.cookieCallOrderData = null">Cancel Order</b-button>
                            </div>
                            <div class="d-none align-items-center justify-content-center">
                                <b-button class="mx-2" @click="sellOrder('CALL')">Sell</b-button>
                                <b-button class="mx-2" @click="cancelAllOrders">Cancel All</b-button>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div class="d-flex align-items-center justify-content-end mb-4">
                            <div class="d-flex align-items-center" style="margin-left: 15px;">
                                <span style="margin-right: 5px">Put Difference Notification :</span>
                                <label class="switch">
                                    <input type="checkbox" v-model="enablePutNotification">
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <div v-if="enablePutNotification" class="d-flex align-items-center"
                                style="margin-left: 15px;">
                                <label for="putSound" style="margin-right: 5px">Select Sound :</label>
                                <select id="putSound" v-model="selectedPutSound" @change="savePutSound">
                                    <option v-for="sound in availableSounds" :key="sound" :value="sound">{{
                                        sound.substring(0,
                                            sound.lastIndexOf('.')) }}</option>
                                </select>
                            </div>
                        </div>

                        <DataTable side="PUT" :latestData="latestData" :selectedLimit="selectedPutLimit"
                            :limits="putLimits" @update:selectedLimit="savePutLimit" />

                        <div v-if="this.cookiePutOrderData && this.selectedOrderPutType !== 'MKT' && this.selectedOrderPutType !== 'TRAIL'"
                            class="w-100 mb-4">
                            <table class="table table-bordered table-striped table-hover table-responsive">
                                <thead>
                                    <tr>
                                        <td colspan="8" style="text-align: center; font-weight: bold;">
                                            <h5>Put Order Details</h5>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><strong>Symbol</strong></td>
                                        <td><strong>Quantity</strong></td>
                                        <td><strong>Order Type</strong></td>
                                        <td><strong>Limit Price</strong></td>
                                        <td><strong>Aux Price</strong></td>
                                        <td><strong>Strike</strong></td>
                                        <td><strong>Security Type</strong></td>
                                        <td><strong>Exchange</strong></td>
                                    </tr>
                                    <tr>
                                        <td>{{ this.cookiePutOrderData.symbol }}</td>
                                        <td>{{ this.cookiePutOrderData.quantity }}</td>
                                        <td>{{ this.cookiePutOrderData.orderType }}</td>
                                        <td>{{ this.cookiePutOrderData.lmtPrice }}</td>
                                        <td>{{ this.cookiePutOrderData.auxPrice }}</td>
                                        <td>{{ this.cookiePutOrderData.strike }}</td>
                                        <td>OPT</td>
                                        <td>SMART</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="d-none flex-column align-items-center justify-content-center mb-4">
                            <div class="d-flex align-items-center justify-content-center mb-4">
                                <div class="mx-2">
                                    <label for="number-select-put" style="margin-right: 5px">Select a size :</label>
                                    <select v-model="selectedPutSize" id="number-select-put">
                                        <option v-for="number in orderSizeLimits" :key="number" :value="number">
                                            {{ number }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mx-2">
                                    <label for="order-type-put" style="margin-right: 5px">Select a order type
                                        :</label>
                                    <select v-model="selectedOrderPutType" id="order-type-put"
                                        @change="(e) => handleOrderType(e.currentTarget.value, 'PUT')">
                                        <option v-for="orderType in orderTypeLimits" :key="orderType"
                                            :value="orderType">
                                            {{ orderType }}
                                        </option>
                                    </select>
                                </div>
                                <div v-if="selectedOrderPutType == 'TRAIL'" class="mx-2">
                                    <label for="trail-type-put" style="margin-right: 5px">Select a Trail Percentage
                                        :</label>
                                    <select v-model="selectedTrailPutPercentage" id="trail-type-put">
                                        <option v-for="percentage in trailPutPercentage" :key="percentage"
                                            :value="percentage">
                                            {{ percentage }}
                                        </option>
                                    </select>
                                </div>
                                <div v-if="selectedOrderPutType == 'STP' || selectedOrderPutType == 'STP_LMT'"
                                    class="mx-2">
                                    <div class="pt-2 d-flex align-items-center justify-content-center">
                                        <p class="mb-0 mx-2">{{ stopPutPrice[0] }}</p>
                                        <b-form-input id="stopPriceSelectPut" v-model="selectedNumberStopPut"
                                            type="range" :min="stopPutPrice[0]" :max="stopPutPrice[1]"
                                            @click="handleOrderData('PUT')" step="0.01"></b-form-input>
                                        <p class="mb-0 mx-2">{{ stopPutPrice[1] }}</p>
                                    </div>
                                    <p class="mt-1 text-center">Stop Price : {{ selectedNumberStopPut }}</p>
                                </div>
                                <div v-if="selectedOrderPutType == 'LMT' || selectedOrderPutType == 'STP_LMT'"
                                    class="mx-2">
                                    <div class="pt-2 d-flex align-items-center justify-content-center">
                                        <p class="mb-0 mx-2">{{ limitPutPrice[0] }}</p>
                                        <b-form-input id="limitPriceSelectPut" v-model="selectedNumberLimitPut"
                                            type="range" :min="limitPutPrice[0]" :max="limitPutPrice[1]"
                                            @click="handleOrderData('PUT')" step="0.01"></b-form-input>
                                        <p class="mb-0 mx-2">{{ limitPutPrice[1] }}</p>
                                    </div>
                                    <p class="mt-1 text-center">Limit Price : {{ selectedNumberLimitPut }}</p>
                                </div>
                                <b-button class="mx-2" @click="buyOrder('PUT')">Buy</b-button>
                                <b-button v-if="this.cookiePutOrderData" class="m-2"
                                    @click="this.cookiePutOrderData = null">Cancel Order</b-button>
                            </div>
                            <div class="d-none align-items-center justify-content-center">
                                <b-button class="mx-2" @click="sellOrder('PUT')">Sell</b-button>
                                <b-button class="mx-2" @click="cancelAllOrders">Cancel All</b-button>
                            </div>
                        </div>

                    </div>
                </div>

                <div style="margin-top: 75px;">
                    <OrderData />
                </div>
            </div>

            <!-- Video components -->
            <div v-if="toggleDataTable">
                <FilterData />
            </div>
            <!-- Audio player for notifications -->
            <audio ref="audioPlayer"></audio>
        </div>
    </div>
</template>

<script>
import socketIo from '@/plugins/socket';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import DataTable from './DataTable.vue';
import FilterData from './FilterData.vue';
import OrderData from './OrderData.vue';
import VueCookie from 'vue-cookie';
import moment from 'moment-timezone';
import { toRaw } from 'vue';

// Initialize toast notifications
const $toast = useToast();

export default {
    components: {
        DataTable,
        FilterData,
        OrderData
    },
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            latestData: null,
            socket: null,
            toggleDataTable: true,
            // Notification Data
            notification: null,
            enablePutNotification: true,
            enableCallNotification: true,
            // enableBaseNotification: true,
            enableCallBaseNotification: true,
            enablePutBaseNotification: true,
            selectedBaseValuePoint: null,
            continuesPlay: 0,
            isUpSound: false,
            availableSounds: [
                "notification_01.mp3",
                "notification_02.mp3",
                "notification_03.mp3",
                "notification_04.mp3",
                "notification_05.mp3",
                "notification_06.mp3",
                "notification_07.mp3",
                "notification_08.mp3",
                "notification_09.mp3",
                "notification_10.mp3",
                "notification_11.mp3",
                "notification_12.wav",
                "notification_13.wav"
            ],
            selectedCallSound: 'notification_12.wav',
            selectedPutSound: 'notification_13.wav',
            selectedBaseUpSound: 'water_drip.mp3',
            selectedBaseDownSound: 'piano_1.mp3',
            baseDifferenceLimits: this.generateFloatNumbers(),
            selectedBaseDifference: '10',
            putLimits: this.generateLimits(),
            selectedPutLimit: '0.00',
            callLimits: this.generateLimits(),
            selectedCallLimit: '0.00',
            // Order Data 
            // Select Order Size
            orderSizeLimits: this.generateNumbers(20, 1),
            selectedCallSize: 1,
            selectedPutSize: 1,
            // Select Order Type
            orderTypeLimits: ['MKT', 'TRAIL'],
            selectedOrderCallType: 'MKT',
            selectedOrderPutType: 'MKT',
            // Select Order Stop Price
            stopCallPrice: [0, 10],
            stopPutPrice: [0, 10],
            selectedNumberStopCall: 0,
            selectedNumberStopPut: 0,
            // Select Order Limit Price
            limitCallPrice: [0, 10],
            limitPutPrice: [0, 10],
            selectedNumberLimitCall: 0,
            selectedNumberLimitPut: 0,
            // Select Order Trailing Percentage
            trailCallPercentage: this.generateFloatNumbers(),
            trailPutPercentage: this.generateFloatNumbers(),
            selectedTrailCallPercentage: 10,
            selectedTrailPutPercentage: 10,
            // Stored Order Data 
            cookieCallOrderData: null,
            cookiePutOrderData: null,
            soundCache: {}
        };
    },
    mounted() {
        const notification = this.$route.query.notification;
        this.notification = notification === 'false' ? false : true;
        this.connectSocketIO();
        this.requestNotificationPermission();
        this.selectedCallSound = VueCookie.get('callSound') || 'notification_12.wav';
        this.selectedPutSound = VueCookie.get('putSound') || 'notification_13.wav';
    },
    watch: {
        selectedBaseDifference(newVal) {
            console.log(newVal); // Log or handle updated value here
        }
    },
    methods: {
        // Request permission for browser notifications
        requestNotificationPermission() {
            if ('Notification' in window) {
                Notification.requestPermission().then(permission => {
                    console.log(`Notification permission ${permission}.`);
                });
            } else {
                console.log('This browser does not support notifications.');
            }
        },

        generateLimits() {
            return Array.from({ length: 21 }, (_, i) => (i * 0.5).toFixed(2));
        },

        generateNumbers(limit, inc) {
            let numbers = [];
            for (let i = 0; i <= limit; i += inc) {
                numbers.push(i);
            }
            return numbers;
        },

        generateFloatNumbers() {
            let numbers = [];

            for (let i = 2; i <= 10; i += 1) {
                numbers.push(i);
            }

            for (let i = 20; i <= 100; i += 10) {
                numbers.push(i);
            }

            return numbers;
        },

        // Determine if a toast should be shown
        shouldShowToast(type, priceDifference) {
            const limit = type === 'call' ? this.selectedCallLimit : this.selectedPutLimit;
            return limit !== '0.00' && Math.max(priceDifference, 0) === Number(limit);
        },

        // Send browser notification
        sendNotification(title, message) {
            if ('Notification' in window && Notification.permission === 'granted') {
                const notification = new Notification(title, { body: message });
                notification.onclick = event => event.preventDefault();
            } else {
                console.log('Notifications are not permitted or supported.');
            }
        },

        showToast(message, type) {
            $toast.open({
                message,
                type,
                duration: 3000,
                dismissible: true,
                queue: true,
            });
        },

        saveSound(type, sound) {
            try {
                // Check if the selected sound is the same as the other sound type
                const otherType = type === 'callSound' ? 'putSound' : 'callSound';
                const otherSound = VueCookie.get(otherType);

                // Store the previous sound before making changes
                const previousSound = VueCookie.get(type);

                if (sound === otherSound) {
                    VueCookie.set(type, otherSound, { expires: '12h' });
                    // Revert the selected sound to the previous sound
                    this[type] = previousSound;

                    alert(`The selected sound is already used for ${otherType}. Please choose a different sound.`);
                    return; // Prevent saving the same sound
                }
                // Save the sound to the cookie
                VueCookie.set(type, sound, { expires: '12h' });
                console.log(`${type} sound saved successfully`);

            } catch (error) {
                console.error(`Failed to save ${type} sound:`, error);
            }
        },

        // Save selected call sound to cookie
        saveCallSound() {
            this.saveSound('callSound', this.selectedCallSound);
            this.playSound(this.selectedCallSound, 1);
        },

        // Save selected put sound to cookie
        savePutSound() {
            this.saveSound('putSound', this.selectedPutSound);
            this.playSound(this.selectedPutSound, 1);
        },

        // Utility function to ensure sounds are cached and preloaded
        preloadSound(sound) {
            if (!this.soundCache[sound]) {
                const audio = new Audio(`/assets/${sound}`);
                audio.load();
                this.soundCache[sound] = audio;
            }
            return this.soundCache[sound];
        },

        // Improved playSound function
        playSound(sound, volume) {
            const audioPlayer = this.preloadSound(sound); // Preload and get cached audio
            if (!audioPlayer) return;

            // Set volume within the valid range
            audioPlayer.volume = Math.max(0, Math.min(volume, 1));

            // Play sound if it's not currently playing
            if (audioPlayer.paused || audioPlayer.currentTime === 0) {
                audioPlayer.currentTime = 0; // Reset to start
                audioPlayer.play()
                    .then(() => {
                        console.log(`Playing sound: ${sound} at volume: ${audioPlayer.volume}`);
                    })
                    .catch(error => console.error('Error playing sound:', error));
            } else {
                // Reset and replay if currently playing
                audioPlayer.pause();
                audioPlayer.currentTime = 0;
                audioPlayer.play()
                    .then(() => {
                        console.log(`Replaying sound: ${sound} at volume: ${audioPlayer.volume}`);
                    })
                    .catch(error => console.error('Error playing sound:', error));
            }
        },

        // Improved playSoundRepeatedly function
        playSoundRepeatedly(sound, volume, repeat) {
            console.log(sound, volume, repeat)
            const audioPlayer = this.preloadSound(sound);
            if (!audioPlayer) return;

            // Set volume within the valid range
            audioPlayer.volume = Math.max(0, Math.min(volume, 1));

            const totalDuration = 1000; // Total time to play sounds (1 second)
            const interval = Math.floor(totalDuration / repeat); // Interval between repeats
            let playCount = 0;

            const playNextSound = () => {
                if (playCount >= repeat) return;

                audioPlayer.currentTime = 0; // Reset to start for each repeat
                audioPlayer.play()
                    .then(() => {
                        playCount++;
                        if (playCount < repeat) {
                            // Schedule the next playback only if repeat count not reached
                            setTimeout(playNextSound, interval);
                        }
                    })
                    .catch(error => console.error('Error playing sound:', error));
            };

            playNextSound(); // Start playing
        },

        // Show toast notification with cooldown
        makeToast(type, message) {
            $toast.open({
                message,
                type: 'default',
                duration: 3000,
                dismissible: true,
                queue: true,
            });

            switch (type) {
                case 'call':
                    if (this.enableCallNotification) {
                        this.playSound(this.selectedCallSound, 1)
                        this.sendNotification('Call Difference', message)
                    }
                    break;
                case 'put':
                    if (this.enablePutNotification) {
                        this.playSound(this.selectedPutSound, 1)
                        this.sendNotification('Put Difference', message)
                    }
                    break;
                default:
                    console.log('sendNotification', message, type);
            }

            const cookieName = type === 'call' ? 'notShowCall' : 'notShowPut';
            VueCookie.set(cookieName, 'true', { expires: new Date(new Date().getTime() + process.env.VUE_APP_NOTIFICATION_DELAY * 1000) });
        },

        // Save and emit new Put limit
        savePutLimit(limit) {
            this.selectedPutLimit = limit;
            this.socket && this.socket.emit('changePutLimit', limit);
        },

        // Save and emit new Call limit
        saveCallLimit(limit) {
            this.selectedCallLimit = limit;
            this.socket && this.socket.emit('changeCallLimit', limit);
        },

        // Handle polygon data received from socket
        handlePolygonData(data) {
            const { callaskPriceDifference, putaskPriceDifference, baseValue, callBidPrice, callAskPrice, putBidPrice, putAskPrice, time } = data;

            if (callBidPrice && callAskPrice) {
                this.selectedNumberLimitCall = Number(callBidPrice);
                this.selectedNumberStopCall = Number(callBidPrice);
                this.limitCallPrice = [Number(callBidPrice).toFixed(2), Number(callAskPrice).toFixed(2)];
                this.stopCallPrice = [Number(callBidPrice).toFixed(2), Number(callAskPrice).toFixed(2)];
            }

            if (putBidPrice && putAskPrice) {
                this.selectedNumberLimitPut = Number(putBidPrice);
                this.selectedNumberStopPut = Number(putBidPrice);
                this.limitPutPrice = [Number(putBidPrice).toFixed(2), Number(putAskPrice).toFixed(2)];
                this.stopPutPrice = [Number(putBidPrice).toFixed(2), Number(putAskPrice).toFixed(2)];
            }

            // Notification logic
            if (this.enableCallBaseNotification || this.enablePutBaseNotification) {
                // Initialize previousBaseValue if not already set
                if (!this.previousBaseValue) {
                    this.previousBaseValue = baseValue;
                } else {
                    // Calculate the difference from the previous base value
                    const baseDiff = Number(this.selectedBaseDifference) / 100;
                    const difference = Number(baseValue) - Number(this.previousBaseValue);
                    const repeatCount = Math.max(1, Math.floor(Math.abs(difference) / baseDiff));

                    console.log("007", time, this.enableCallBaseNotification, this.enablePutBaseNotification);

                    if (this.enableCallBaseNotification && difference >= baseDiff) {
                        console.log("007 difference call", difference, baseDiff, repeatCount);
                        // Positive difference: Play upward sound
                        this.playSoundRepeatedly(this.selectedBaseUpSound, 1, Math.min(repeatCount, 5));
                    }

                    if (this.enablePutBaseNotification && difference <= -baseDiff) {
                        console.log("007 difference put", difference, -baseDiff, repeatCount);
                        // Negative difference: Play downward sound
                        this.playSoundRepeatedly(this.selectedBaseDownSound, 1, Math.min(repeatCount, 5));
                    }

                    // Update the previous base value for the next comparison
                    this.previousBaseValue = baseValue;
                }
            } else {
                this.previousBaseValue = null;
            }

            // Check for notifications with call and put differences
            const notShowCall = VueCookie.get('notShowCall');
            const notShowPut = VueCookie.get('notShowPut');

            if (this.shouldShowToast('call', callaskPriceDifference) && !notShowCall && this.notification && this.enableCallNotification) {
                this.makeToast('call', `Call side difference reached ${this.selectedCallLimit}`);
            }

            if (this.shouldShowToast('put', putaskPriceDifference) && !notShowPut && this.notification && this.enablePutNotification) {
                this.makeToast('put', `Put side difference reached ${this.selectedPutLimit}`);
            }
        },

        handleOrderType(value, orderType) {

            if (orderType == "PUT") {
                this.selectedOrderPutType = value
            } else {
                this.selectedOrderCallType = value
            }

            if (value === "MKT") {
                orderType === "PUT" ? this.cookiePutOrderData = null : this.cookieCallOrderData = null
            }
        },

        handleOrderData(orderType) {
            const orderData = {
                symbol: 'SPX',
                quantity: orderType === "PUT" ? this.selectedPutSize : this.selectedCallSize,
                orderType: orderType === "PUT" ? this.selectedOrderPutType : this.selectedOrderCallType,
                lmtPrice: orderType === "PUT" ? this.selectedNumberLimitPut === 0 ? Number(this.latestData?.putBidPrice) : Number(this.selectedNumberLimitPut) : this.selectedNumberLimitCall === 0 ? Number(this.latestData?.callBidPrice) : Number(this.selectedNumberLimitCall),
                auxPrice: orderType === "PUT" ? this.selectedNumberStopPut === 0 ? Number(this.latestData?.putBidPrice) : Number(this.selectedNumberStopPut) : this.selectedNumberStopCall === 0 ? Number(this.latestData?.callBidPrice) : Number(this.selectedNumberStopCall),
                trailingPercent: orderType === "PUT" ? this.selectedTrailPutPercentage : this.selectedTrailCallPercentage,
                account: process.env.VUE_APP_ACCOUNT_ID,
                secType: "OPT",
                exchange: 'SMART',
                strike: this.latestData?.call || 0,
                expiry: moment(this.latestData?.date, 'MM/DD/YYYY').format('YYYYMMDD'),
                right: orderType === "PUT" ? "P" : "C",
            }

            if (orderType === "PUT") {
                this.cookiePutOrderData = orderData
            } else {
                this.cookieCallOrderData = orderData
            }
        },

        async executeOrder(action, orderDetails) {
            try {
                console.log("executeOrder", action, orderDetails)
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/${action}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(orderDetails),
                })

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                // console.log("executeOrder cookieOrderData before reset:", orderDetails?.right === "P" ? this.cookiePutOrderData : this.cookieCallOrderData);
                orderDetails?.right === "P" ? this.cookiePutOrderData = null : this.cookieCallOrderData = null
                // console.log("executeOrder cookieOrderData after reset:", orderDetails?.right === "P" ? this.cookiePutOrderData : this.cookieCallOrderData);
                this.showToast(data.message, 'success');
            } catch (error) {
                console.error(`Error placing ${action} order:`, error);
                this.showToast(`Error placing ${action} order`, 'error');
            }
        },

        async buyOrder(orderType) {
            const cookieOrderData = orderType === "PUT" ? this.cookiePutOrderData : this.cookieCallOrderData
            await this.executeOrder('buy', cookieOrderData ? toRaw(cookieOrderData) : {
                symbol: 'SPX',
                quantity: orderType === "PUT" ? this.selectedPutSize : this.selectedCallSize,
                orderType: orderType === "PUT" ? this.selectedOrderPutType : this.selectedOrderCallType,
                lmtPrice: orderType === "PUT" ? this.selectedNumberLimitPut === 0 ? Number(this.latestData?.putBidPrice) : Number(this.selectedNumberLimitPut) : this.selectedNumberLimitCall === 0 ? Number(this.latestData?.callBidPrice) : Number(this.selectedNumberLimitCall),
                auxPrice: orderType === "PUT" ? this.selectedNumberStopPut === 0 ? Number(this.latestData?.putBidPrice) : Number(this.selectedNumberStopPut) : this.selectedNumberStopCall === 0 ? Number(this.latestData?.callBidPrice) : Number(this.selectedNumberStopCall),
                trailingPercent: orderType === "PUT" ? this.selectedTrailPutPercentage : this.selectedTrailCallPercentage,
                account: process.env.VUE_APP_ACCOUNT_ID,
                secType: "OPT",
                exchange: 'SMART',
                strike: this.latestData?.call || 0,
                expiry: moment(this.latestData?.date, 'MM/DD/YYYY').format('YYYYMMDD'),
                right: orderType === "PUT" ? "P" : "C",
            });
        },

        async sellOrder(orderType) {
            const cookieOrderData = orderType === "PUT" ? this.cookiePutOrderData : this.cookieCallOrderData
            await this.executeOrder('sell', cookieOrderData ? toRaw(cookieOrderData) : {
                symbol: 'SPX',
                quantity: orderType === "PUT" ? this.selectedPutSize : this.selectedCallSize,
                orderType: orderType === "PUT" ? this.selectedOrderPutType : this.selectedOrderCallType,
                lmtPrice: orderType === "PUT" ? this.selectedNumberLimitPut === 0 ? Number(this.latestData?.putBidPrice) : Number(this.selectedNumberLimitPut) : this.selectedNumberLimitCall === 0 ? Number(this.latestData?.callBidPrice) : Number(this.selectedNumberLimitCall),
                auxPrice: orderType === "PUT" ? this.selectedNumberStopPut === 0 ? Number(this.latestData?.putBidPrice) : Number(this.selectedNumberStopPut) : this.selectedNumberStopCall === 0 ? Number(this.latestData?.callBidPrice) : Number(this.selectedNumberStopCall),
                account: process.env.VUE_APP_ACCOUNT_ID,
                secType: "OPT",
                exchange: 'SMART',
                strike: this.latestData?.put || 0,
                expiry: moment(this.latestData?.date, 'MM/DD/YYYY').format('YYYYMMDD'),
                right: orderType === "PUT" ? "P" : "C",
            });
        },

        async cancelAllOrders() {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/cancelAll`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.showToast(data.message, 'success');
            } catch (error) {
                console.error('Error canceling all orders:', error);
                this.showToast('Error canceling all orders', 'error');
            }
        },

        // Connect to Socket.IO
        connectSocketIO() {
            this.socket = socketIo

            this.socket.on('connect', () => {
                // console.log('Socket.IO connection established.');
                this.socket.emit('message', 'Hello server, send me polyData!');
            });

            this.socket.on('polygonData', data => {
                this.latestData = data;
                this.handlePolygonData(data);
            });

            this.socket.on('callLimit', data => {
                if (this.selectedCallLimit !== data) {
                    // console.log('Changed call limit:', this.selectedCallLimit, data);
                    this.selectedCallLimit = data;
                }
            });

            this.socket.on('putLimit', data => {
                if (this.selectedPutLimit !== data) {
                    // console.log('Changed put limit:', this.selectedPutLimit, data);
                    this.selectedPutLimit = data;
                }
            });

            this.socket.on('orderStatus', data => {
                if (data?.orderId) {
                    let message = `${data?.orderAction} ${data?.quantity} ${data?.symbol} @ ${data?.strike} is ${data?.orderStatus}`

                    switch (data?.orderStatus) {
                        case 'Cancelled':
                            this.showToast(message, 'error')
                            break;
                        case 'Pending':
                            this.showToast(message, 'info')
                            break;
                        case 'Filled':
                            message = `${data?.orderAction} ${data?.quantity} ${data?.symbol} @ ${data?.strike} is ${data?.orderStatus} with ${data?.avgFillPrice}`
                            this.showToast(message, 'success')
                            break;
                        case 'Inactive':
                            this.showToast(message, 'error')
                            break;
                        case 'PreSubmitted':
                            this.showToast(message, 'info')
                            break;
                        case 'Submitted':
                            this.showToast(message, 'success')
                            break;
                    }
                }
            })

            this.socket.on('orderError', data => this.showToast(data, 'error'))

            this.socket.on('disconnect', () => {
                // console.log('Socket.IO connection closed.');
            });

            this.socket.on('error', error => {
                console.error('Socket.IO error:', error);
            });
        },
    },
};
</script>

<style scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

select {
    height: 38px;
}
</style>